/**
 * @param {string} string string value you would like to capitalize
 */
const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Check if two arrays have the same values i.e. are equal. We can't use JS
 * strict equality because it compares the references as opposed to the values.
 *
 * Open to suggestions on how this could be more efficient. Perhaps a for loop
 * is more memory efficient as we don't create a set object in memory, thoughts?
 *
 * CAVEATS
 * - does not work for multi-dimensional arrays
 * - does not handle the case if you have duplicate elements within the array
 * @param {array} arrayA
 * @param {array} arrayB
 */
const checkArrayValueEquality = (arrayA, arrayB) => {
  // If array's aren't equal, don't bother to continue, their values are not equal
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  // Add both arrays as a new Set. This should remove duplicates between both
  // arrays, so should only be a set of unique values between the two arrays
  // e.g. ['a','b','c', 'y'] + ['c','a','b', 'x'] = ['a','b','c', 'x', 'y']
  const combined = new Set([...arrayA, ...arrayB]);
  // if the size of the set does not match the arrays, then there are non-matching values
  if (combined.size !== arrayA.length) {
    return false;
  }

  return true;
};

/**
 * @param {any} object
 */
const isObject = (object) => (
  object !== null && typeof object === 'object'
);

/**
 * Check if one object has all values of another object.
 * Useful for checking if values of form inputs have been changed or not.
 *
 * CAVEATS
 * Does not handle for value as an array
 * If you want to go further, please implement with checkArrayValueEquality above
 * @param {object} parent
 * @param {object} child
 * @param {array} exceptKeys values in child not related to comparison
 */
const checkObjectValuePossessive = (parent, child, exceptKeys) => {
  // not acceptable if parent and child are not object type
  if (!isObject(parent) || !isObject(child)) {
    return false;
  }

  const exceptedKeys = Array.isArray(exceptKeys) ? exceptKeys : [];
  const parentKeys = Object.keys(parent).filter((key) => !exceptedKeys.includes(key));
  const childKeys = Object.keys(child).filter((key) => !exceptedKeys.includes(key));

  // if child object has more keys than parent's, they always are not equal
  if (childKeys.length > parentKeys.length) {
    return false;
  }

  return !childKeys.some((key) => {
    const childVal = child[key];
    const parentVal = parent[key];
    const areObjects = isObject(childVal) && isObject(parentVal);

    // call this function recursive if values are object too
    return (areObjects && !checkObjectValuePossessive(parentVal, childVal))
            || (!areObjects && childVal !== parentVal);
  });
};

/**
 * Get the suffix to display after the port unlocode
 * @param {string} portType
 */
const getPortSuffix = (portType) => {
  switch (portType) {
  case 'ocean':
    return '港';
  case 'air':
    return '空港';
  case 'rail':
    return 'Rail';
  default:
    // Optionally handle the case where portType is none of the above
    return 'Unknown';
  }
};

/**
 * Get the suffix to display after port unlocode
 * @param {bool} isAirCargo
 * @returns { '空港' | '港' }
 */
const portSuffixFromBool = (isAirCargo) => (isAirCargo ? '空港' : '港');

// eslint-disable-next-line arrow-body-style
const randomId = () => {
  // simple random with letter and numbers for id's useful only for FE only generates 10,000
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

/**
 * Remove keys from object recursively.
 * Useful for removing unwanted keys before submit.
 * eg. __typename.
 *
 * @param {object} obj
 * @param {array} keys
 */
const removeKeys = (obj, keys) => {
  if (obj !== Object(obj)) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeys(item, keys));
  }
  return Object.keys(obj).filter((k) => !keys.includes(k)).reduce(
    (acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }),
    {},
  );
};

/**
 * generate flat object to a nested object.
 *
 * @param {object} objectData value of current object (can be an object)
 * @param {object} keyArray key array that splitted.
 * eg. ['shipmentArgs', 'houseBlShipper', 'tradePartnerDetails', 'faxNumber']
 * @param {object} objectValue actual value of current object that need to send
 */
const objectBuilder = (objectData, keyArray, objectValue) => {
  if (keyArray.length === 1) {
    return { ...objectData, [keyArray[0]]: objectValue };
  }
  const currentKey = keyArray.shift();
  return {
    ...objectData,
    [currentKey]: objectBuilder(objectData?.[currentKey], keyArray, objectValue),
  };
};

/**
 * generate flat object to a nested object.
 * eg. { 'shipmentArgs.houseBlShipper.tradePartnerDetails.faxNumber' : '0123-45'}
 * will become
 * {
 *    shipmentArgs: {
 *      houseBlShipper: {
 *        tradePartnerDetails: {
 *          faxNumber: '0123-45'
 *        }
 *      }
 *    }
 * }
 *
 * @param {object} inputData
 * @param {object} coreFrame a frame that result should always have
 */
const buildObjectInputs = (inputData, coreFrame) => {
  const result = { ...coreFrame };

  if (inputData) {
    Object.keys(inputData).forEach((inputKey) => {
      if (inputKey.includes('.')) {
        const keyArray = inputKey.split('.');
        const currentKey = keyArray.shift();
        result[currentKey] = objectBuilder(
          result[currentKey],
          keyArray,
          inputData[inputKey],
        );
      } else {
        result[inputKey] = inputData[inputKey];
      }
    });
  }

  return result;
};

/*
 * To get the value from local storage that matches the given key
 * @param {string} key
 * @returns The value of the key argument | `undefined` if not found or invalid key argument
 */
const parseLocalStorageJSON = (key) => {
  if (typeof key !== 'string' || !key.trim()) {
    return null;
  }

  try {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  } catch (e) {
    window.rollbar.warn('Error parsing JSON from localStorage for key:', key, e);
    return null;
  }
};
/**
 * To set the key-value pair to local storage
 * @param {string} key
 * @param {any} value
 * @returns N/A
 */
const setToLocalStorage = (key, value) => {
  if (!key || typeof key !== 'string') return;
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Generate 32 digit hexadecimal UUID-like string, but without hyphens.
 * When in secure context (connected via https or to localhost)
 * uses the browser's built-in function intended for creating random UUIDs.
 * In other contexts, uses built-in function to generate 32 random digits;
 * @note the returned string is not a valid UUID
 * @returns string
 */
const randomID = () => {
  if (typeof window.crypto.randomUUID === 'function') {
    const hyphenatedUUID = window.crypto.randomUUID();
    return hyphenatedUUID.replace(/-/gi, '');
  }

  const uint8Array = new Uint8Array(16);
  window.crypto.getRandomValues(uint8Array);
  return [...uint8Array].map((v) => v.toString(16)).join('');
};

export {
  capitalize,
  checkArrayValueEquality,
  checkObjectValuePossessive,
  getPortSuffix,
  portSuffixFromBool,
  randomId,
  removeKeys,
  buildObjectInputs,
  parseLocalStorageJSON,
  setToLocalStorage,
  randomID,
};
