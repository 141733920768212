import { gql } from '@apollo/client';

export default gql`
  query ShipmentInternalAssignees(
    $shipmentId: ID!
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
    ) {
      id
      internalAssignees {
        ...on CustomerUser {
          id
          name
        }
        ...on UserGroup {
          id
          name
        }
      }
    }
  }
`;
