import { gql } from '@apollo/client';

export default gql`
  mutation uploadAttachment(
    $relatedObject: RelatedObjectInput!
    $attachmentArgs: RemoteDirectUploadLinkInput!
    $shipmentParticipantGroupId: ID
  ) {
    uploadAttachment(
      relatedObject: $relatedObject
      attachmentArgs: $attachmentArgs
      shipmentParticipantGroupId: $shipmentParticipantGroupId
    ) {
      id
      name
      url
    }
  }
`;
