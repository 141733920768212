import { gql } from '@apollo/client';

export default gql`
  mutation SoftDeleteUser($userId: ID!, $loginUserType: LoginUserTypeEnum!) {
    softDeleteUser(userId: $userId, loginUserType: $loginUserType) {
      ... on CustomerUser {
        id
      }
      ... on PartnerUser {
        id
      }
      ... on CustomerPartnerUser {
        id
      }
    }
  }
`;
