import { gql } from '@apollo/client';

export default gql`
  fragment ChannelFields on Shipment {
    channels {
      id
      shipmentParticipantGroup {
        groupMembers {
          id
          name
          type
        }
      }
      channelContexts {
        contextType
        contextId
      }
      participantsType
      unreadMessageCount
    }
  }
`;
