import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchAttachments(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      customerTeam {
        id
      }
      availableAttachmentsWithPrivate {
        id
        categories
        ocrResult
        shipmentParticipantGroup {
          id
          groupMembers {
            id
          }
        }
        createdAt
        isPrivate
        isRelatedToTask
        name
        uploadedAt
        uploader {
          id
          name
        }
        url
      }
      shipmentParticipantGroups {
        id
        groupMembers {
          id
          name
        }
      }
    }
  }
`;
