import { gql } from '@apollo/client';

export default gql`
  mutation CreateUserAssignment($args: UserAssignmentInput!) {
    createUserAssignment(
      args: $args
    ) {
      assigneeId
    }
  }
`;
