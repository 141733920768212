import { gql } from '@apollo/client';

export default gql`
  query AssignableSearch(
    $assignedToId: String!,
    $assignedToType: AssignedToTypeEnum!,
    $searchTerm: String!,
  ) {
    assignableSearch(
      assignedToId: $assignedToId,
      assignedToType: $assignedToType,
      searchTerm: $searchTerm,
    ) {
      ...on CustomerUser {
          id
          name
          email
        }
      ...on UserGroup {
        id
        name
      }
    }
  }
`;
