import { gql } from '@apollo/client';
import { SHIPMENT_CHANNEL_FIELDS } from '../fragments/Channel';

export default gql`
  query ShipmentChannelSearch(
    $shipmentId: ID
    $searchString: String
    $tradeDirection: TradeDirectionEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: {
        searchString: $searchString
        tradeDirection: $tradeDirection
      }
      pagination: $pagination
    ) {
      id
      ...ChannelFields
    }
  }
  ${SHIPMENT_CHANNEL_FIELDS}
`;
