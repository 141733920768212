import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const SERVICE_NAME = 'back-office';
const isDatadogActivated = process.env.REACT_APP_ENV !== 'development';

export const setUpDatadogRum = () => {
  if (isDatadogActivated) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      service: SERVICE_NAME,
      env: process.env.REACT_APP_ENV,
      version: process.env.REACT_APP_COMMIT_HASH,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      trackErrors: true,
      sampleRate: 100,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      traceSampleRate: 100,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        process.env.REACT_APP_GRAPHQL_API,
      ],
      enableExperimentalFeatures: ['feature_flags'],
    });
    datadogRum.startSessionReplayRecording();
  }
};

export const setUpDataDogLogs = () => {
  if (isDatadogActivated) {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      env: process.env.REACT_APP_ENV,
      service: SERVICE_NAME,
      version: process.env.REACT_APP_COMMIT_HASH,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
    });
  }
};
