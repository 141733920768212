import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './assets/base.scss';
import App from './App';
import configureStore from './config/configureStore';
import { AuthTokenProvider } from './context/AuthTokenContext';
import { setUpDataDogLogs, setUpDatadogRum } from './lib/datadog';

const store = configureStore();
const rootElement = document.getElementById('root');

setUpDatadogRum();
setUpDataDogLogs();

const renderApp = async () => {
  /**
   * Initializing the LaunchDarkly client
   * `asyncWithLDProvider` is used to prevent flicker due to flag changes at startup time.
   * @see https://docs.launchdarkly.com/sdk/client-side/react/react-web
   */
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  ReactDOM.render(
    <LDProvider>
      <AuthTokenProvider>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ReduxProvider>
      </AuthTokenProvider>
    </LDProvider>,
    rootElement,
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
